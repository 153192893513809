export default {
	apiUrl: 'https://mccaxalta.com/api/'
};
const siteConfig = {
	siteName: 'Axalta Marketing ContactCENTER',
	siteIcon: 'ion-flash',
	footerText: ''
};

const themeConfig = {
	topbar: 'themedefault',
	sidebar: 'themedefault',
	layout: 'themedefault',
	theme: 'themedefault'
};
const language = 'spanish';
const AlgoliaSearchConfig = {
	appId: '',
	apiKey: ''
};
const Auth0Config = {
	domain: '',
	clientID: '',
	allowedConnections: ['Username-Password-Authentication'],
	rememberLastLogin: true,
	language: 'es',
	closable: true,
	options: {
		auth: {
			autoParseHash: true,
			redirect: true,
			redirectUrl: 'http://localhost:3000/auth0loginCallback'
		},
		languageDictionary: {
			title: 'Axalta Marketing ContactCENTER',
			emailInputPlaceholder: 'demo@gmail.com',
			passwordInputPlaceholder: 'demodemo'
		},
		theme: {
			labeledSubmitButton: true,
			logo: '',
			primaryColor: '#E14615',
			authButtons: {
				connectionName: {
					displayName: 'Log In',
					primaryColor: '#b7b7b7',
					foregroundColor: '#000000'
				}
			}
		}
	}
};
const firebaseConfig = {
	//PROD
	apiKey: "AIzaSyA0cQR6V8bxSRfNL50UraSeBYpKyS8mktA",
  	authDomain: "axaltabrandcenter-guat.firebaseapp.com",
	databaseURL: 'https://axaltabrandcenter-guat.firebaseio.com',
  	projectId: "axaltabrandcenter-guat",
  	storageBucket: "axaltabrandcenter-guat.appspot.com",
  	messagingSenderId: "515191613139",
  	appId: "1:515191613139:web:ee7e4099ddbcbf4c3f1626"

	//QA
	// apiKey: 'AIzaSyAe0Y-14o-hFQX66o9Pjy4Dj0k9Jd3eGqY',
	// authDomain: 'brandcenter-d4aa8.firebaseapp.com',
	// databaseURL: 'https://brandcenter-d4aa8.firebaseio.com',
	// projectId: 'brandcenter-d4aa8',
	// storageBucket: 'brandcenter-d4aa8.appspot.com',
	// messagingSenderId: '264369132572',
	// appId: '1:264369132572:web:8ac070beca2d74fa'
};
const googleConfig = {
	apiKey: 'AIzaSyCMbn7PomDMDNZD41qrr3NY08KSTsp23pI'
};
const mapboxConfig = {
	tileLayer: '',
	maxZoom: '',
	defaultZoom: '',
	center: []
};
const youtubeSearchApi = '';
export {
	siteConfig,
	themeConfig,
	language,
	AlgoliaSearchConfig,
	Auth0Config,
	firebaseConfig,
	googleConfig,
	mapboxConfig,
	youtubeSearchApi
};
