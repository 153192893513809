import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import Firebase from '../../helpers/firebase/index';
import _ from 'lodash';
import { notification } from '@iso/components/index';

const { firestore, database, getNewsFeed } = Firebase;
// const COLLECTION_NAME = 'downloads';
// const nameDecodedRegex = /.*\/o(.*\/)(.*)\.(.*)\?.*/g;

const getDocuments = state => state.newsFeed.documents;

function* loadDocuments({ payload: { type, subType } }) {
	try {
		const documents = yield call(
			async () => await getNewsFeed(type, subType)
		);
		yield put(actions.loadDocumentsSuccess(documents));
	} catch (error) {
		console.log(error);
		yield put(actions.loadDocumentsFailed(error));
	}
}

function* filterDocuments({ payload: { searchText = '' } }) {
	try {
		const documents = yield select(getDocuments);
		const searchStrings = searchText.split(' ');
		if (searchText.trim().length === 0) {
			yield put(actions.clearFilters());
		} else {
			var results = _.filter(documents, document => {
				var found = false;
				const {
					title = '',
					text = '',
					subtitle = '',
					footer = ''
				} = document;
				searchStrings.forEach(textToSearch => {
					if (
						!found &&
						title
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
					if (
						!found &&
						subtitle
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
					if (
						!found &&
						text
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
					if (
						!found &&
						footer
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
				});
				return found;
			});
			yield put(actions.filterDocumentsSuccess(results));
		}
	} catch (error) {
		console.log(error);
		yield put(actions.filterDocumentsFailed(error));
	}
}

const deleteDocumentAsc = async (type, documentId) =>
	await database
		.collection(`newsFeed/feedTables/${type}`)
		.doc(documentId)
		.delete()
		.then(result => {
			return { result };
		})
		.catch(error => {
			throw error;
		});

function* deleteDocument({ payload: { type, documentId } }) {
	try {
		const result = yield call(
			async () => await deleteDocumentAsc(type, documentId)
		);
		console.debug(result);
		yield put(actions.deleteDocumentSuccess());
		notification('success', 'Documento eliminado correctamente');
		yield put(actions.loadDocuments(type));
	} catch (error) {
		console.log(error);
		yield put(actions.deleteDocumentFailed(error));
		notification('error', 'Hubo un error al eliminar el documento');
	}
}
const removeEmpty = obj => {
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
		else if (obj[key] === undefined) delete obj[key];
	});
	return obj;
};
const saveDocumentAsc = async (type, docId, data) => {
	const table = database.collection(`newsFeed/feedTables/${type}`);
	const doc = !docId ? table.doc() : table.doc(docId);
	if (!docId) {
		data.created = firestore.FieldValue.serverTimestamp();
	}
	// removeEmpty(data);
	return await doc
		.set(data)
		.then(result => {
			return { result };
		})
		.catch(error => {
			throw error;
		});
};
function* saveDocument({ payload: { type, data } }) {
	try {
		const { id: docId, ...dataToSave } = data;
		yield call(async () => await saveDocumentAsc(type, docId, dataToSave));
		yield put(actions.saveDocumentSuccess());
		notification('success', 'Documento guardado correctamente');
		yield put(actions.loadDocuments(type));
	} catch (error) {
		console.log(error);
		yield put(actions.saveDocumentFailed(error));
		notification('error', 'Hubo un error al guardar el documento');
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_DOCUMENTS, loadDocuments),
		takeEvery(actions.FILTER_DOCUMENTS, filterDocuments),
		takeEvery(actions.DELETE_DOCUMENT, deleteDocument),
		takeEvery(actions.SAVE_DOCUMENT, saveDocument)
	]);
}
