const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	IS_ADMIN: 'IS_ADMIN',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
	SIGNUP_ERROR: 'SIGNUP_ERROR',
	AUTH_STATE_CHANGED: 'AUTH_STATE_CHANGED',

	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	login: (
		token = false,
		uid = false,
		userRole = false,
		userName = false,
		surName = false,
		email = false,
		displayName = false,
		phoneNumber = false,
		photoURL = false,
		admin = false
	) => ({
		type: actions.LOGIN_REQUEST,
		payload: {
			token,
			uid,
			userRole,
			userName,
			surName,
			email,
			displayName,
			phoneNumber,
			photoURL,
			admin
		}
	}),
	signup: (token = false) => ({
		type: actions.SIGNUP_SUCCESS,
		payload: {
			token
		}
	}),
	logout: () => ({ type: actions.LOGOUT }),
	isAdmin: ({ admin = false }) => {
		return {
			type: actions.IS_ADMIN,
			admin: admin
		};
	},
	authStateChanged: ({ user }) => ({
		type: actions.AUTH_STATE_CHANGED,
		user: user
	})
};
export default actions;
