const DOCUMENT = 'BANNER_';

const actions = {
	LOAD_BANNERS: DOCUMENT + 'LOAD_BANNERS',
	LOAD_BANNERS_SUCCESS: DOCUMENT + 'LOAD_BANNERS_SUCCESS',
	LOAD_BANNERS_FAILED: DOCUMENT + 'LOAD_BANNERS_FAILED',
	REMOVE_BANNER: DOCUMENT + 'REMOVE_BANNER',
	REMOVE_BANNER_SUCCESS: DOCUMENT + 'REMOVE_BANNER_SUCCESS',
	REMOVE_BANNER_FAILED: DOCUMENT + 'REMOVE_BANNER_FAILED',

	EDIT_DOCUMENT: DOCUMENT + 'EDIT_DOCUMENT',
	EDIT_DOCUMENT_CANCEL: DOCUMENT + 'EDIT_DOCUMENT_CANCEL',
	SAVE_DOCUMENT: DOCUMENT + 'SAVE_DOCUMENT',
	SAVE_DOCUMENT_SUCCESS: DOCUMENT + 'SAVE_DOCUMENT_SUCCESS',
	SAVE_DOCUMENT_FAILED: DOCUMENT + 'SAVE_DOCUMENT_FAILED',

	loadBanners: () => ({ type: actions.LOAD_BANNERS }),
	loadBannersSuccess: data => {
		return {
			type: actions.LOAD_BANNERS_SUCCESS,
			payload: { data }
		};
	},
	loadBannersFailed: error => ({
		type: actions.LOAD_BANNERS_FAILED,
		payload: { error }
	}),
	removeBanner: bannerId => {
		return {
			type: actions.REMOVE_BANNER,
			payload: { bannerId }
		};
	},
	removeBannerSuccess: data => {
		return {
			type: actions.REMOVE_BANNER_SUCCESS,
			payload: { data }
		};
	},
	removeBannerFailed: error => {
		return {
			type: actions.REMOVE_BANNER_FAILED,
			payload: { error }
		};
	},
	saveDocument: data => ({
		type: actions.SAVE_DOCUMENT,
		payload: data
	}),
	saveDocumentSuccess: () => ({
		type: actions.SAVE_DOCUMENT_SUCCESS
	}),
	saveDocumentFailed: error => ({
		type: actions.SAVE_DOCUMENT_FAILED,
		payload: { error }
	}),
	editDocument: () => ({
		type: actions.EDIT_DOCUMENT
	}),
	editDocumentCancel: () => ({
		type: actions.EDIT_DOCUMENT_CANCEL
	})
};

export default actions;
