const CATALOGUE = 'CATALOGUE_';

const actions = {
	LOAD_CATALOGUE: CATALOGUE + 'LOAD_CATALOGUE',
	LOAD_CATALOGUE_SUCCESS: CATALOGUE + 'LOAD_CATALOGUE_SUCCESS',
	LOAD_CATALOGUE_FAILED: CATALOGUE + 'LOAD_CATALOGUE_FAILED',

	DELETE_CATALOGUE: CATALOGUE + 'DELETE_CATALOGUE',
	DELETE_CATALOGUE_SUCCESS: CATALOGUE + 'DELETE_CATALOGUE_SUCCESS',
	DELETE_CATALOGUE_FAILED: CATALOGUE + 'DELETE_CATALOGUE_FAILED',

	UPDATE_CATALOGUE: CATALOGUE + 'UPDATE_CATALOGUE',
	UPDATE_CATALOGUE_SUCCESS: CATALOGUE + 'UPDATE_CATALOGUE_SUCCESS',
	UPDATE_CATALOGUE_CANCEL: CATALOGUE + 'UPDATE_CATALOGUE_CANCEL',

	SAVE_CATALOGUE: CATALOGUE + 'SAVE_CATALOGUE',
	SAVE_CATALOGUE_SUCCESS: CATALOGUE + 'SAVE_CATALOGUE_SUCCESS',
	SAVE_CATALOGUE_FAILED: CATALOGUE + 'SAVE_CATALOGUE_FAILED',

	loadCatalogue: () => ({
		type: actions.LOAD_CATALOGUE
	}),
	loadCatalogueSuccess: cats => ({
		type: actions.LOAD_CATALOGUE_SUCCESS,
		payload: { catalogue: cats }
	}),
	loadCatalogueFailed: error => ({
		type: actions.LOAD_CATALOGUE_FAILED,
		payload: { error }
	}),
	deleteCatalogue: idCat => ({
		type: actions.DELETE_CATALOGUE,
		payload: idCat
	}),
	deleteCatalogueSuccess: () => ({
		type: actions.DELETE_CATALOGUE_SUCCESS
	}),
	deleteCatalogueFailed: error => ({
		type: actions.DELETE_CATALOGUE_FAILED,
		payload: { error }
	}),
	saveCatalogue: catalogue => ({
		type: actions.SAVE_CATALOGUE,
		payload: catalogue
	}),
	saveCatalogueSuccess: () => ({
		type: actions.SAVE_CATALOGUE_SUCCESS
	}),
	saveCatalogueFailed: error => ({
		type: actions.SAVE_CATALOGUE_FAILED,
		payload: { error }
	}),
	
	updateCatalogue: catalogue => ({
		type: actions.UPDATE_CATALOGUE,
		payload: catalogue
	}),
	updateCatalogueSuccess: () => ({
		type: actions.UPDATE_CATALOGUE_SUCCESS
	}),
	updateCatalogueCancel: () => ({
		type: actions.UPDATE_CATALOGUE_CANCEL
	})
};

export default actions;
