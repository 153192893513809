import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './users/saga';
import newsFeedSaga from './newsFeed/saga';
import mapSaga from './maps/saga';
import qrCodeSagas from './qrCode/saga';
import dealsSaga from './deals/saga';
import messagesSaga from './messages/saga';
import segmentsSaga from './segments/saga';
import bannersSaga from './banners/saga';
import githubSearchSagas from './githubSearch/sagas';
import directorySaga from './directory/saga';
import catalogueSaga from './catalogue/saga';

export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		qrCodeSagas(),
		mapSaga(),
		newsFeedSaga(),
		userSagas(),
		dealsSaga(),
		segmentsSaga(),
		messagesSaga(),
		bannersSaga(),
		githubSearchSagas(),
		directorySaga(),
		catalogueSaga()
	]);
}
