import actions from './actions';

const initState = {
	catalogue: []
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_CATALOGUE:
			return {
				...state
			}
		case actions.LOAD_CATALOGUE_SUCCESS:
			return {
				...state,
				catalogue: payload.catalogue
			}
		case actions.LOAD_CATALOGUE_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.DELETE_CATALOGUE:
			return {
				...state,
			}
		case actions.DELETE_CATALOGUE_SUCCESS:
			return {
				...state,
				catalogue: payload.catalogue
			}
		case actions.DELETE_CATALOGUE_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.SAVE_CATALOGUE:
			return {
				...state
			}
		case actions.SAVE_CATALOGUE_SUCCESS:
			return {
				...state,
				catalogue: payload.catalogue
			}
		case actions.SAVE_CATALOGUE_FAILED:
			return {
				...state,
				error: payload.error
			}
		default:
			return state;
	}
}
