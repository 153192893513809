import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const Page404 = ({ location }) => (
	<Route render={props => <Redirect to="/dashboard" from="old-match" />} />
);

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/signin',
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);
const PublicRoutes = ({ history, isLoggedIn }) => {
	return (
		<BrowserRouter history={history}>
			<div>
				<Switch>
					<Route
						exact
						path={'/'}
						component={asyncComponent(() =>
							import('./containers/Signin/signin')
						)}
					/>
					<Route
						exact
						path={'/termsConditions'}
						component={asyncComponent(() =>
							import(
								'./containers/Page/termsConditions'
							)
						)}
					/>
					<Route
						exact
						path={'/500'}
						component={asyncComponent(() =>
							import('./containers/Page/500')
						)}
					/>
					<Route
						exact
						path={'/signin'}
						component={asyncComponent(() =>
							import('./containers/Signin/signin')
						)}
					/>
					<Route
						exact
						path={'/signup'}
						component={asyncComponent(() =>
							import('./containers/Signin/signup')
						)}
					/>
					<Route
						exact
						path={'/forgotpassword'}
						component={asyncComponent(() =>
							import('./containers/Page/forgotPassword')
						)}
					/>
					<Route
						exact
						path={'/resetpassword'}
						component={asyncComponent(() =>
							import('./containers/Page/resetPassword')
						)}
					/>
					<Route
						exact
						path={'/register'}
						component={asyncComponent(() =>
							import('./containers/User/register')
						)}
					/>

					<RestrictedRoute
						path="/dashboard"
						component={App}
						isLoggedIn={isLoggedIn}
					/>
					<Redirect from="/old-match" to="" />
					<Route component={Page404} />
				</Switch>
			</div>
		</BrowserRouter>
	);
};

export default connect(state => ({
	isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);