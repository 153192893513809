import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import Firebase from '../../helpers/firebase/index';
import { notification } from '@iso/components/index';

const { saveDirectory, getDirectory, updateDirectory, deleteDirectory } = Firebase;

function* loadDirectoryAsc() {
	try {
		const directory = yield call(
			async () => await getDirectory()
		);
		const result = [];
		directory.forEach(value => {
			result.push({
				key: value.id,
				...value
			})
		})
		yield put(actions.loadDirectorySuccess(result));
	} catch (error) {
		console.log(error);
		yield put(actions.loadDirectoryFailed(error));
	}
}

function* deleteDirectoryAsc({ payload: { idDoc } }) {
	try {
		yield call( async () => await deleteDirectory(idDoc) );
		notification('success', 'Registro eliminado correctamente');
		yield put(actions.loadDirectory());
	} catch (error) {
		console.log(error);
		yield put(actions.deleteDirectoryFailed(error));
		notification('error', 'Hubo un error al eliminar el directory');
	}
}

function* saveDirectoryAsc({ payload: { directory } }) {
	try {
		yield call(async () => await saveDirectory(directory) );
		notification('success', 'Registro guardado correctamente');
		yield put(actions.loadDirectory());
	} catch (error) {
		console.log(error);
		yield put(actions.saveDirectoryFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

function* updateDirectoryAsc({ payload: { directory } }) {
	try {
		yield call(async () => await updateDirectory(directory));
		yield put(actions.saveDirectorySuccess());
		notification('success', 'Registro actualizado correctamente');
		yield put(actions.loadDirectory());
	} catch (error) {
		console.log(error);
		yield put(actions.saveDirectoryFailed(error));
		notification('error', 'Hubo un error al guardar el Registro');
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_DIRECTORY, loadDirectoryAsc),
		takeEvery(actions.UPDATE_DIRECTORY, updateDirectoryAsc),
		takeEvery(actions.DELETE_DIRECTORY, deleteDirectoryAsc),
		takeEvery(actions.SAVE_DIRECTORY, saveDirectoryAsc)
	]);
}
