import clone from 'clone';
import actions from './actions';

const initState = {
    qrCodes: [],
    initialQRCodes: false,
    currentQRCode: {},
    editableQRCode: {},
    isNewQRCode: false,
    enableEditView: false,
    isLoading: false,
    errorMessage: false,
    modalActive: false,
};

export default function cardReducer(state = initState, {
    type,
    payload,
    ...action
}) {
    switch (type) {
        case actions.UPDATE_QRCODE:
            {
                const currentQRCode = action.qrCode ?
                    action.qrCode : state.currentQRCode;
                return {
                    ...state,
                    qrCodes: action.qrCodes,
                    currentQRCode: clone(currentQRCode),
                    initialQRCodes: true,
                    isNewQRCode: false,
                    enableEditView: false
                };
            }
        case actions.SELECT_CURRENT_QRCODE:
            {
                const qrCodes = state.qrCodes;
                const index = qrCodes
                    .map(qrCode => qrCode.id)
                    .indexOf(action.id);
                const isNewQRCode = index === -1;
                const currentQRCode = isNewQRCode ? {
                    id: action.id,
                    number: `#${action.id}`,
                    key: action.id,
                } : qrCodes[index];
                const enableEditView = isNewQRCode;
                return {
                    ...state,
                    currentQRCode,
                    isNewQRCode,
                    enableEditView,
                    editableQRCode: clone(currentQRCode)
                };
            }
        case actions.TOGGLE_VIEW:
            return {
                ...state,
                enableEditView: action.view,
                editableQRCode: clone(state.currentQRCode)
            };
        case actions.UPDATE_EDIT_QRCODE:
            return {
                ...state,
                editableQRCode: clone(action.qrCode)
            };
        case actions.LOAD_FROM_FIRESTORE:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
                modalActive: false
            };
        case actions.LOAD_FROM_FIRESTORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                qrCodes: payload.data,
                errorMessage: false
            };
        case actions.LOAD_FROM_FIRESTORE_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'There is a loading problem'
            };
        case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                investor: payload.data == null ?
                    initState.investor : payload.data
            };
        case actions.FIRESTORE_UPDATE:
            return {
                ...state,
                qrCode: payload.data
            };
        case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'There is a loading problem'
            };
        default:
            return state;
    }
}