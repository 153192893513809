import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import FirebaseHelper from '../../helpers/firebase/index';
import '@firebase/firestore';


const {
    // database,
    rsfFirestore,
    getDeals
} = FirebaseHelper;

const COLLECTION_NAME = 'Deals'; // change your collection


const loadFromFirestoreAs = async() =>
    await getDeals()
    .then(data => {
        const dealsResult = [];
        data.data.forEach(deals => {
            dealsResult.push(deals);
        });
        actions.loadFromFireStoreSuccess(dealsResult);
        put({ type: actions.LOAD_FROM_FIRESTORE_SUCCESS, payload: { dealsResult } });
        return dealsResult;
    })
    .catch(error => {
        console.log(error);
        actions.loadFromFireStoreError(error);
    });

function* loadFromFirestore() {
    try {
        const dealsRes = yield call(loadFromFirestoreAs());
        yield put(actions.loadFromFireStoreSuccess(dealsRes));
    } catch (error) {
        console.log(error);
    }
}

function* storeIntoFirestore({ payload }) {
    const { data, actionName } = payload;
    try {
        switch (actionName) {
            case 'delete':
                yield call(rsfFirestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
                    deleted_at: new Date().getTime(),
                });
                break;
            case 'update':
                yield call(rsfFirestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
                    ...omit(data, ['key']),
                });
                break;
            default:
                yield call(rsfFirestore.addDocument, COLLECTION_NAME, data);
                break;
        }
        yield put({ type: actions.LOAD_FROM_FIRESTORE });
    } catch (error) {
        console.log(error);
        yield put(actions.saveIntoFireStoreError(error));
    }
}

// const readAllFirestoreDocuments = async() =>
//     await database
//     .collection(COLLECTION_NAME)
//     .get()
//     .then(querySnapshot => {
//         const documents = [];
//         try {
//             querySnapshot.forEach(doc => {
//                 documents.push(doc.id);
//             });
//         } catch (e) {}
//         return documents;
//     });

const getDealsByTypeAsc = async({value}) => 
    await getDeals(value)
        .then(data => {
            const dealsResult = [];
            data.data.forEach(deals => {
                dealsResult.push(deals);
            });
            return dealsResult;
        })
        .catch(error => {
            console.log(error);
            throw error;
        });

function* getDealsByType(value) {
    try {
        const result = yield getDealsByTypeAsc(value);
        yield actions.getDealsByTypeSuccess(result);
        yield put({ type: actions.LOAD_BY_TYPE_FIRESTORE_SUCCESS, payload: { data:result } });
        return result;
    } catch (error) {
        yield actions.loadFromFireStoreError(error);
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
        takeEvery(actions.LOAD_BY_TYPE_FIRESTORE, getDealsByType),
    ]);
}