import actions from './actions';

const initState = {
	messagesCount: 0,
	messagesNewsCount: 0,
	messagesDealsCount: 0,
	messagesMarketingCount: 0,
	messageReceived: {},
	messageSelected: {}
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.MESSAGE_RECEIVED:
			console.log('messages reducer MESSAGE_RECEIVED ', payload);
			return {
				...state,
				messagesCount: state.messagesCount + 1,
				messageReceived: payload.data
			};
		default:
			return state;
	}
}
