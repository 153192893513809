const DOCUMENT = 'SEGMENTS_';

const actions = {
	CLEAR_DOCUMENTS: DOCUMENT + 'CLEAR_DOCUMENTS',
	LOAD_DOCUMENTS: DOCUMENT + 'LOAD_DOCUMENTS',
	LOAD_DOCUMENTS_SUCCESS: DOCUMENT + 'LOAD_DOCUMENTS_SUCCESS',
	LOAD_DOCUMENTS_FAILED: DOCUMENT + 'LOAD_DOCUMENTS_FAILED',
	SET_TYPES: DOCUMENT + 'SET_TYPES',
	FILTER_DOCUMENTS: DOCUMENT + 'FILTER_DOCUMENTS',
	FILTER_DOCUMENTS_SUCCESS: DOCUMENT + 'FILTER_DOCUMENTS_SUCCESS',
	FILTER_DOCUMENTS_FAILED: DOCUMENT + 'FILTER_DOCUMENTS_FAILED',
	CLEAR_FILTERS: DOCUMENT + 'CLEAR_FILTERS',

	EDIT_DOCUMENT: DOCUMENT + 'EDIT_DOCUMENT',
	EDIT_DOCUMENT_CANCEL: DOCUMENT + 'EDIT_DOCUMENT_CANCEL',
	SAVE_DOCUMENT: DOCUMENT + 'SAVE_DOCUMENT',
	SAVE_DOCUMENT_SUCCESS: DOCUMENT + 'SAVE_DOCUMENT_SUCCESS',
	SAVE_DOCUMENT_FAILED: DOCUMENT + 'SAVE_DOCUMENT_FAILED',
	SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
	SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

	SHOW_PREVIEW: DOCUMENT + 'SHOW_PREVIEW',
	SHOW_PREVIEW_CANCEL: DOCUMENT + 'SHOW_PREVIEW_CANCEL',

	clearDocuments: () => {
		return {
			type: actions.CLEAR_DOCUMENTS
		};
	},
	setTypes: data => {
		return {
			type: actions.SET_TYPES,
			payload: { data }
		};
	},
	loadDocuments: ({ segment, section }) => {
		return {
			type: actions.LOAD_DOCUMENTS,
			payload: { segment, section }
		};
	},
	loadDocumentsSuccess: data => {
		return {
			type: actions.LOAD_DOCUMENTS_SUCCESS,
			payload: { documents: data }
		};
	},
	loadDocumentsFailed: error => {
		return {
			type: actions.LOAD_DOCUMENTS_FAILED,
			payload: { error: error }
		};
	},
	filterDocuments: searchText => {
		return {
			type: actions.FILTER_DOCUMENTS,
			payload: { searchText }
		};
	},
	filterDocumentsSuccess: data => {
		return {
			type: actions.FILTER_DOCUMENTS_SUCCESS,
			payload: { documents: data }
		};
	},
	filterDocumentsFailed: error => {
		return {
			type: actions.FILTER_DOCUMENTS_FAILED,
			payload: { error: error }
		};
	},
	clearFilters: () => {
		return {
			type: actions.CLEAR_FILTERS
		};
	},
	saveIntoFireStore: (data, actionName = 'insert', segment, section) => ({
		type: actions.SAVE_INTO_FIRESTORE,
		payload: {
			data,
			segment,
			section,
			actionName
		}
	}),
	saveIntoFireStoreError: error => ({
		type: actions.SAVE_INTO_FIRESTORE_ERROR,
		payload: {
			error
		}
	}),
	saveDocument: data => ({
		type: actions.SAVE_DOCUMENT,
		payload: data
	}),
	saveDocumentSuccess: () => ({
		type: actions.SAVE_DOCUMENT_SUCCESS
	}),
	saveDocumentFailed: error => ({
		type: actions.SAVE_DOCUMENT_FAILED,
		payload: { error }
	}),
	editDocument: () => ({
		type: actions.EDIT_DOCUMENT
	}),
	editDocumentCancel: () => ({
		type: actions.EDIT_DOCUMENT_CANCEL
	}),
	showPreview: () => ({
		type: actions.SHOW_PREVIEW
	}),
	showPreviewCancel: () => ({
		type: actions.SHOW_PREVIEW_CANCEL
	})
};

export default actions;
