import actions from './actions';

const initState = {
	documents: [],
	isLoading: false,
	isDeleting: false,
	isSaving: false,
	showEditVisible: false,
	type: null,
	subType: null,
	filteredDocuments: [],
	isFiltered: false,
	selectedDocument: null
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_DOCUMENTS:
			return {
				...state,
				type: payload.type,
				subType: payload.subType,
				isLoading: true,
				documents: [],
				isFiltered: false,
				isDeleting: false,
				filteredDocuments: [],
				selectedDocument: false
			};
		case actions.LOAD_DOCUMENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				documents: payload.documents
			};
		case actions.LOAD_DOCUMENTS_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload.error
			};
		// case actions.DELETE_DOCUMENT:
		// 	return {
		// 		...state,
		// 		type: payload.type,
		// 		documentId: payload.documentId
		// 	};
		case actions.DELETE_DOCUMENT:
			return {
				...state,
				type: payload.type,
				documentId: payload.documentId,
				isDeleting: true
			};
		case actions.DELETE_DOCUMENT_SUCCESS:
			return {
				...state,
				isDeleting: false
			};
		case actions.DELETE_DOCUMENT_FAILED:
			return {
				...state,
				isDeleting: false,
				error: payload.error
			};
		case actions.SAVE_DOCUMENT:
			return {
				...state,
				isSaving: true,
				document: payload.data,
				type: payload.type
			};
		case actions.SAVE_DOCUMENT_SUCCESS:
			return {
				...state,
				isSaving: false,
				showEditVisible: false
			};
		case actions.SAVE_DOCUMENT_FAILED:
			return {
				...state,
				isSaving: false,
				showEditVisible: false,
				error: payload.error
			};
		case actions.EDIT_DOCUMENT:
			return {
				...state,
				showEditVisible: true
			};
		case actions.EDIT_DOCUMENT_CANCEL:
			return {
				...state,
				showEditVisible: false
			};
		case actions.CLEAR_FILTERS:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		case actions.FILTER_DOCUMENTS:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: payload.searchText
			};
		case actions.FILTER_DOCUMENTS_SUCCESS:
			return {
				...state,
				isFiltered: true,
				filteredDocuments: payload.documents
			};
		case actions.FILTER_DOCUMENTS_FAILED:
			return {
				...state,
				isFiltered: false,
				filteredDocuments: [],
				searchText: ''
			};
		default:
			return state;
	}
}
