import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { push } from 'react-router-redux';
import {
	getToken,
	clearToken,
	clearUID,
	clearUserRole,
	clearUserData,
	getUID,
	getUserRole,
	getUserData
} from '../../helpers/utility';
import actions from './actions';
import Firebase from '../../helpers/firebase';

export function* loginRequest() {
	yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
		const {
			token,
			uid,
			userRole,
			userName,
			surName,
			email,
			displayName,
			phoneNumber,
			photoURL,
			admin
		} = payload;
		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token: token,
				profile: 'Profile',
				uid: uid,
				userRole: userRole,
				userName: userName,
				surName: surName,
				email: email,
				displayName: displayName,
				phoneNumber: phoneNumber,
				photoURL: photoURL,
				admin: admin
			});
		} else {
			yield put({ type: actions.LOGIN_ERROR });
		}
	});
}

export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
		yield localStorage.setItem('id_token', payload.token);
		yield localStorage.setItem('uid', payload.uid);
		yield localStorage.setItem('userRole', payload.userRole);
		yield localStorage.setItem('userName', payload.userName);
		yield localStorage.setItem('surName', payload.surName);
	});
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function*() {});
}
export function* signUpSuccess() {
	yield takeEvery(actions.SIGNUP_SUCCESS, function*(payload) {
		yield localStorage.setItem('id_token', payload.token);
		yield localStorage.setItem('sapCode', payload.sapCode);
		yield localStorage.setItem('user', payload.user);
	});
}

export function* signUpError() {
	yield takeEvery(actions.SIGNUP_ERROR, function*() {});
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function*() {
		clearToken();
		clearUID();
		clearUserRole();
		clearUserData();
		Firebase.logout();
		yield put(push('/'));
	});
}
/* 
const isAdminFunction = (cbchannel, admin) => {
	cbchannel.put(actions.isAdmin({ admin }));
	return admin;
};
 */
export function* checkAuthorization() {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
		const token = getToken().get('idToken');
		const uid = getUID().get('uid');
		const userRole = getUserRole().get('userRole');
		const userName = getUserData().get('userName');
		const surName = getUserData().get('surName');
		const admin = Firebase.isAdmin(uid);
		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token,
				profile: 'Profile',
				uid: uid,
				userRole: userRole,
				userName: userName,
				surName: surName,
				admin: admin
			});
			/* const isAdminChannel = channel();
			Firebase.isAdminCallback(admin => {
				isAdminChannel.put(isAdminFunction(isAdminChannel, admin));
			});
			const thisAction = yield take(isAdminChannel);
			yield put(thisAction); */
		}
	});
}

export function* authStatusChange(): Generator<void, void, void> {
	const authStatusChannel = channel();
	Firebase.authStateChanged(user => {
		authStatusChannel.put(actions.authStateChanged({ user }));
	});

	while (true) {
		const action = yield take(authStatusChannel);
		yield put(action);
	}
}

export function* watchAuthStatusChange(): Generator<void, void, void> {
	yield call(authStatusChange);
}

export function* authStateChanged() {
	yield takeEvery(actions.AUTH_STATE_CHANGED, function*(payload) {
		const { user } = payload;
		if (user) {
			const isAdmin = yield call(async () => await Firebase.isAdmin());
			yield put(actions.isAdmin({ admin: isAdmin }));
		} else {
			yield put(actions.isAdmin({ admin: null }));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(signUpSuccess),
		fork(signUpError),
		fork(logout),
		fork(authStateChanged)
	]);
	yield [watchAuthStatusChange()];
}
