import styled from 'styled-components';
import { transition, borderRadius } from '@iso/settings/style-util';
import WithDirection from '@iso/settings/withDirection';

const ThemeSwitcherStyle = styled.div`
  
  width: 340px;
  background-color: transparent;
  height: calc(100% - 70px);
  padding: 0 0 50px;
  flex-shrink: 0;
  position: fixed;
  top: 70px;
  right: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '-340px')};
  left: ${props => (props['data-rtl'] === 'rtl' ? '-340px' : 'inherit')};
  z-index: 1001;
  ${transition()};
  

  @media only screen and (max-width: 767px) {
    width: 270px;
    right: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '-270px')};
    left: ${props => (props['data-rtl'] === 'rtl' ? '-270px' : 'inherit')};
  }

  &.active {
    right: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
    left: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
  }

  .switcher {
    right: ${props => (props['data-rtl'] === 'rtl' ? '-98px' : 'inherit')};
    left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '-98px')};
  }

 

  .SwitcherBlockWrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 105px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .themeSwitchBlock {
      width: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      margin-top: 30px;

      h4 {
        font-size: 14px;
        font-weight: 700;
       
        line-height: 1.3;
        margin-bottom: 0;
        padding: 0 15px;
        text-transform: uppercase;
      }

      .themeSwitchBtnWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px 20px;

        button {
          width: 20px;
          height: 20px;
          display: flex;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 10px' : '0 10px 0 0'};
         
          outline: 0;
          padding: 0;
          
          justify-content: center;
          position: relative;
          cursor: pointer;
          ${borderRadius('3px')};

          &.languageSwitch {
            border: 0;
            width: 30px;
            height: auto;

            &.selectedTheme {
              &:before,
              &:after {
                top: 2px;
                left: ${props =>
                  props['data-rtl'] === 'rtl' ? 'inherit' : '-3px'};
                right: ${props =>
                  props['data-rtl'] === 'rtl' ? '-3px' : 'inherit'};
              }
            }
          }

          img {
            width: 100%;
          }

          &.selectedTheme {
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              display: -webkit-inline-flex;
              display: -ms-inline-flex;
              display: inline-flex;
             
              position: absolute;
              top: -2px;
              left: ${props =>
                props['data-rtl'] === 'rtl' ? 'inherit' : '-2px'};
              right: ${props =>
                props['data-rtl'] === 'rtl' ? '-2px' : 'inherit'};
              ${borderRadius('50%')};
            }

            &:after {
              content: '';
              width: 6px;
              height: 6px;
              display: -webkit-inline-flex;
              display: -ms-inline-flex;
              display: inline-flex;
             
              position: absolute;
              top: -2px;
              left: ${props =>
                props['data-rtl'] === 'rtl' ? 'inherit' : '-2px'};
              right: ${props =>
                props['data-rtl'] === 'rtl' ? '-2px' : 'inherit'};
              -webkit-animation: selectedAnimation 1.2s infinite ease-in-out;
              animation: selectedAnimation 1.2s infinite ease-in-out;
              ${borderRadius('50%')};
            }
          }
        }
      }
    }
  }

  .switcherToggleBtn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    position: absolute;
    text-align: center;
    top: 200px;
    left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '-70px')};
    right: ${props => (props['data-rtl'] === 'rtl' ? '-50px' : 'inherit')};
    cursor: pointer;
    

    img {
      width: 50px;
    }
  }

  

  @-webkit-keyframes selectedAnimation {
    0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
      transform: scale(2.4);
      opacity: 0;
    }
  }
  @keyframes selectedAnimation {
    0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(2.4);
      transform: scale(2.4);
      opacity: 0;
    }
  }
`;

export default WithDirection(ThemeSwitcherStyle);
