/* Languages 
import Zhlang from './entries/zh-Hans-CN';
import Salang from './entries/ar_SA';
import Itlang from './entries/it_IT';
import Frlang from './entries/fr_FR'; 
*/
import Enlang from './entries/en-US';
import Eslang from './entries/es_ES';
import { addLocaleData } from 'react-intl';

const AppLocale = {
    en: Enlang,
    es: Eslang,
    /*  
     zh: Zhlang,
     sa: Salang,
     it: Itlang, 
    fr: Frlang 
    */
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);
/* 
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.sa.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.fr.data);
 */
export default AppLocale;