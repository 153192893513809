const DIRECTORY = 'DIRECTORY_';

const actions = {
	LOAD_DIRECTORY: DIRECTORY + 'LOAD_DIRECTORY',
	LOAD_DIRECTORY_SUCCESS: DIRECTORY + 'LOAD_DIRECTORY_SUCCESS',
	LOAD_DIRECTORY_FAILED: DIRECTORY + 'LOAD_DIRECTORY_FAILED',

	DELETE_DIRECTORY: DIRECTORY + 'DELETE_DIRECTORY',
	DELETE_DIRECTORY_SUCCESS: DIRECTORY + 'DELETE_DIRECTORY_SUCCESS',
	DELETE_DIRECTORY_FAILED: DIRECTORY + 'DELETE_DIRECTORY_FAILED',

	UPDATE_DIRECTORY: DIRECTORY + 'UPDATE_DIRECTORY',
	UPDATE_DIRECTORY_SUCCESS: DIRECTORY + 'UPDATE_DIRECTORY_SUCCESS',
	UPDATE_DIRECTORY_FAILED: DIRECTORY + 'UPDATE_DIRECTORY_CANCEL',

	SAVE_DIRECTORY: DIRECTORY + 'SAVE_DIRECTORY',
	SAVE_DIRECTORY_SUCCESS: DIRECTORY + 'SAVE_DIRECTORY_SUCCESS',
	SAVE_DIRECTORY_FAILED: DIRECTORY + 'SAVE_DIRECTORY_FAILED',

	loadDirectory: () => ({
		type: actions.LOAD_DIRECTORY
	}),
	loadDirectorySuccess: doc => ({
		type: actions.LOAD_DIRECTORY_SUCCESS,
		payload: { directory: doc }
	}),
	loadDirectoryFailed: error => ({
		type: actions.LOAD_DIRECTORY_FAILED,
		payload: { error }
	}),
	deleteDirectory: idDoc => ({
		type: actions.DELETE_DIRECTORY,
		payload: idDoc
	}),
	deleteDirectorySuccess: () => ({
		type: actions.DELETE_DIRECTORY_SUCCESS
	}),
	deleteDirectoryFailed: error => ({
		type: actions.DELETE_DIRECTORY_FAILED,
		payload: { error }
	}),
	saveDirectory: doc => ({
		type: actions.SAVE_DIRECTORY,
		payload: doc
	}),
	saveDirectorySuccess: () => ({
		type: actions.SAVE_DIRECTORY_SUCCESS
	}),
	saveDirectoryFailed: error => ({
		type: actions.SAVE_DIRECTORY_FAILED,
		payload: { error }
	}),
	
	updateDirectory: doc => ({
		type: actions.UPDATE_DIRECTORY,
		payload: { directory: doc }
	}),
	updateDirectorySuccess: () => ({
		type: actions.UPDATE_DIRECTORY_SUCCESS
	}),
	updateDirectoryCancel: () => ({
		type: actions.UPDATE_DIRECTORY_FAILED
	})
};

export default actions;
