import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '@iso/helpers/AsyncFunc';
import { connect } from 'react-redux';

const Page404 = ({ location }) => (
	<Route render={props => <Redirect to="/dashboard" from="old-match" />} />
);

const customRoutes = [
	{
		path: 'news',
		component: asyncComponent(() => import('@iso/components/newsFeeds'))
	},
	{
		path: 'directory',
		component: asyncComponent(() =>
			import('@iso/containers/Directory/directory.js')
		)
	},
	{
		path: 'Marketing',
		component: asyncComponent(() => import('@iso/components/newsFeeds'))
	},

	{
		path: 'dashUser',
		component: asyncComponent(() => import('@iso/containers/User/index'))
	},
	{
		path: 'registerAxalta',
		component: asyncComponent(() => import('@iso/containers/User/register'))
	},
	{
		path: 'corporate/tutorials',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'corporate/downloads',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'deals/architectonic',
		component: asyncComponent(() => import('@iso/components/newsFeeds'))
	},
	{
		path: 'deals/automotive',
		component: asyncComponent(() => import('@iso/components/newsFeeds'))
	},
	{
		path: 'architectonic/colorTools',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'architectonic/downloads',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'architectonic/securityPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'architectonic/technicalPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'architectonic/bulletin',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'fleets/colorTools',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'fleets/downloads',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'fleets/securityPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'fleets/technicalPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'fleets/bulletin',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'industry/colorTools',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'industry/downloads',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'industry/securityPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'industry/technicalPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'industry/bulletin',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'repAuto/colorTools',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'repAuto/downloads',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'repAuto/securityPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'repAuto/technicalPage',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	},
	{
		path: 'repAuto/bulletin',
		component: asyncComponent(() => import('@iso/containers/Submenus'))
	}
]

const routes = [
	{
		path: '',
		component: asyncComponent(() => import('../Widgets/index.js'))
	},
	{
		path: '/dashboard',
		component: asyncComponent(() => import('../App/App.js'))
	},
	{
		path: 'StoreRegister',
		component: asyncComponent(() =>
			import('../Map/GoogleMap/StoreRegister/index')
		)
	},
	{
		path: 'contacts',
		component: asyncComponent(() => import('../Contacts'))
	},
	{
		path: 'profileUpdate',
		component: asyncComponent(() => import('../User/EditProfile'))
	},
	{
		path: 'mobile',
		component: asyncComponent(() => import('../Page/mobile'))
	},
	{
		path: 'storeLoc',
		component: asyncComponent(() => import('../Map/GoogleMap/googleMap'))
	},
	...customRoutes
];

const adminRoutes = [
	{
		path: '/dashUser',
		component: asyncComponent(() => import('../User/index'))
	},
	{
		path: 'userList',
		component: asyncComponent(() => import('../User/userList')),
		exact: false
	},
	{
		path: 'register',
		component: asyncComponent(() => import('../User/register'))
	},
	{
		path: 'banners',
		component: asyncComponent(() => import('../BannerAdmin'))
	},
	{
		path: 'catalogues',
		component: asyncComponent(() => import('../Catalogues'))
	}
];

class AppRouter extends Component {
	render() {
		const { url, style, admin } = this.props;
		return (
			<div style={style}>
				<Switch>
					{routes.map(singleRoute => {
						const { path, exact, ...otherProps } = singleRoute;
						return (
							<Route
								exact={exact === false ? false : true}
								key={singleRoute.path}
								path={`${url}/${singleRoute.path}`}
								{...otherProps}
							/>
						);
					})}
					{admin &&
						adminRoutes.map(singleRoute => {
							const { path, exact, ...otherProps } = singleRoute;
							return (
								<Route
									exact={exact === false ? false : true}
									key={singleRoute.path}
									path={`${url}/${singleRoute.path}`}
									{...otherProps}
								/>
							);
						})}
					<Redirect from="/old-match" to="" />
					<Route component={Page404} />
				</Switch>
			</div>
		);
	}
}
const mapStateToProps = state => {
	const { admin = false, idToken } = state.Auth;
	return {
		isLoggedIn: idToken !== null,
		admin
	};
};
export default connect(mapStateToProps)(AppRouter);
