import React, { Component } from 'react';
import WhatsappStyle from './whatsapp.style';
import whatsapp from '@iso/image/whatsapp.png'

class Whatsapp extends Component {
    render() {
        const {
            isActivated
        } = this.props;

        return (
        // <WhatsappStyle className = {
        //         isActivated ?
        //         'isoThemeSwitcher active' :
        //             'isoThemeSwitcher'
        //         } >

            <div className = 'switcherToggleBtn' >
                <a
                // href="whatsapp://send?text=Hola! Vengo del contactCenter de Axalta©&phone=5215569165914">
                href = "https://wa.me/50242158313?text=Hola!%20Vengo%20del%20contactCenter%20de%20Axalta©" >
                    <span style={{color:'#FFFFFF', marginRight:'10px'}}><strong>Contáctanos</strong></span>
                    <img src = { whatsapp } width="40" height="40"
                    alt = "Contáctanos por whatsapp" />
                </a>
            </div>

                // </WhatsappStyle>
        );
    }
}

export default Whatsapp;
