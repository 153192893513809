import actions from './actions';

const initState = {
	directory: []
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_DIRECTORY:
			return {
				...state
			}
		case actions.LOAD_DIRECTORY_SUCCESS:
			return {
				...state,
				directory: payload.directory
			}
		case actions.LOAD_DIRECTORY_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.DELETE_DIRECTORY:
			return {
				...state,
			}
		case actions.DELETE_DIRECTORY_SUCCESS:
			return {
				...state,
				directory: payload.directory
			}
		case actions.DELETE_DIRECTORY_FAILED:
			return {
				...state,
				error: payload.error
			}

		case actions.SAVE_DIRECTORY:
			return {
				...state
			}
		case actions.SAVE_DIRECTORY_SUCCESS:
			return {
				...state,
				directory: payload.directory
			}
		case actions.SAVE_DIRECTORY_FAILED:
			return {
				...state,
				error: payload.error
			}
		case actions.UPDATE_DIRECTORY:
			return {
				...state
			}
		case actions.UPDATE_DIRECTORY_SUCCESS:
			return {
				...state,
				directory: payload.directory
			}
		case actions.UPDATE_DIRECTORY_FAILED:
			return {
				...state,
				error: payload.error
			}
		default:
			return state;
	}
}
