import Auth from './auth/reducer';
import App from './app/reducer';
import qrCode from './qrCode/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Users from './users/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import maps from './maps/reducer';
import newsFeed from './newsFeed/reducer';
import deals from './deals/reducer';
import messages from './messages/reducer';
import segments from './segments/reducer';
import banners from './banners/reducer';
import githubSearch from './githubSearch/reducers';
import Directory from './directory/reducer';
import Catalogue from './catalogue/reducer';

export default {
	Auth,
	App,
	ThemeSwitcher,
	LanguageSwitcher,
	maps,
	qrCode,
	newsFeed,
	Users,
	deals,
	messages,
	segments,
	banners,
	githubSearch,
	Directory,
	Catalogue
};
