import actions from './actions';

const initState = {
	idToken: null,
	uid: null,
	userName: null,
	surName: null,
	email: null,
	displayName: null,
	phoneNumber: null,
	photoURL: null,
	admin: null
};

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				idToken: action.token,
				uid: action.uid,
				userRole: action.userRole,
				userName: action.userName,
				surName: action.surName,
				email: action.email,
				displayName: action.displayName,
				phoneNumber: action.phoneNumber,
				photoURL: action.photoURL,
				admin: action.admin
			};
		case actions.IS_ADMIN:
			return { ...state, admin: action.admin };
		case actions.SIGNUP_SUCCESS:
			return { ...state, idToken: action.token };
		case actions.AUTH_STATE_CHANGED:
			return { ...state };
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
}
