const DOCUMENT = 'PUSH_';

const actions = {
	MESSAGE_RECEIVED: DOCUMENT + 'MESSAGE_RECEIVED',
	MESSAGE_CLICKED: DOCUMENT + 'MESSAGE_CLICKED',

	messageReceived: data => {
		console.log('messageReceived action', data);
		return {
			type: actions.MESSAGE_RECEIVED,
			payload: { data }
		};
	},

	messageClicked: data => {
		console.log('messageClicked action', data);
		return {
			type: actions.MESSAGE_CLICKED,
			payload: { data }
		};
	}
};

export default actions;
