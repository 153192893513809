import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import Firebase from '../../helpers/firebase/index';
import { notification } from '@iso/components/index';
import _ from 'lodash';

const { getDownloads, rsfFirestore } = Firebase;
const COLLECTION_NAME = 'downloads';
const nameDecodedRegex = /.*\/o(.*\/)(.*)\.(.*)\?.*/g;

const getDocuments = state => state.segments.documents;

const getDocumentInfo = downloadUrl => {
	const decodedDownload = decodeURIComponent(downloadUrl);
	let fileLocation = '';
	let fileName = '';
	let fileExtension = '';
	try {
		fileLocation = decodedDownload.replace(nameDecodedRegex, '$1');

		fileExtension = decodedDownload
			.replace(nameDecodedRegex, '$3')
			.trim()
			.toLowerCase();
		fileName =
			decodedDownload.replace(nameDecodedRegex, '$2') +
			'.' +
			fileExtension;
		return { fileName, fileExtension, fileLocation };
	} catch (error) {
		console.log(error);
		throw error;
	}
};

function* loadDocuments({ payload: { segment, section } }) {
	try {
		const tmpDocuments = yield call(
			async () => await getDownloads(segment, section)
		);
		const documents = [];
		tmpDocuments.forEach(document => {
			const docInfo = getDocumentInfo(document.downloadUrl);
			documents.push({
				...document,
				...docInfo
			});
		});
		yield put(actions.loadDocumentsSuccess(documents));
	} catch (error) {
		yield put(actions.loadDocumentsFailed(error));
	}
}

function* filterDocuments({ payload: { searchText } }) {
	try {
		const documents = yield select(getDocuments);
		//		console.log('filterDocuments', documents, searchText);
		const searchStrings = searchText.split(' ');
		if (searchText.trim().length === 0) {
			yield put(actions.clearFilters());
		} else {
			var results = _.filter(documents, document => {
				var found = false;
				const { title = '', text = '', fileName = '' } = document;
				searchStrings.forEach(textToSearch => {
					if (
						!found &&
						title
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
					if (
						!found &&
						text
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}

					if (
						!found &&
						fileName
							.trim()
							.toLowerCase()
							.indexOf(textToSearch) > -1
					) {
						found = true;
					}
				});
				return found;
			});
			yield put(actions.filterDocumentsSuccess(results));
		}
	} catch (error) {
		console.log(error);
		yield put(actions.filterDocumentsFailed(error));
	}
}

function* storeIntoFireStore({ payload }) {
	const { data, actionName, segment, section } = payload;
	try {
		switch (actionName) {
			case 'delete':
				yield call(
					rsfFirestore.deleteDocument,
					`${COLLECTION_NAME}/${segment}/${section}/${data.id}`,
					{
						['key']: `${data.id}`
					}
				);
				notification('success', 'Se eliminó el archivo correctamente');
				break;
			case 'update':
			case 'accept':
				yield call(
					rsfFirestore.updateDocument,
					`${COLLECTION_NAME}/${data.id}`,
					{ status: 1 }
				);
				break;
			default:
				yield call(rsfFirestore.addDocument, COLLECTION_NAME, data);
				break;
		}

		yield put(actions.loadDocuments({ segment, section }));
	} catch (error) {
		console.log(error);
		yield put(actions.saveIntoFireStoreError(error));
	}
}

function* saveDocument({
	payload: { segment = '', section = '', id, downloadUrl = '', title = '', category = '' }
}) {
	try {
		if (
			downloadUrl.trim().length === 0 ||
			segment.length === 0 ||
			section === 0
		) {
			throw new Error({
				errorCode: 88123479,
				description:
					'Para guardar un documento, necesita tener una imagen'
			});
		}
		var downloadDoc = {
			segment,
			section,
			downloadUrl,
			category
		};
		if (title.trim().length > 0) {
			downloadDoc.title = title;
		}
		if (id) {
			downloadDoc.id = id;
		}
		yield call(
			async () =>
				await Firebase.saveDownloads({
					segment,
					section,
					downloadUrl,
					title,
					category,
					id
				})
		);
		yield put(actions.saveDocumentSuccess());
		notification('success', 'El documento se guardó correctamente');
		yield put(actions.loadDocuments({ segment, section }));
	} catch (error) {
		const { errorCode, description } = error;
		if (errorCode && errorCode === 88123479) {
			notification('error', description);
			return;
		}
		yield put(actions.saveDocumentFailed(error));
		notification(
			'error',
			'No se pudo guardar el documento, intente mas tarde'
		);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFireStore),
		takeEvery(actions.LOAD_DOCUMENTS, loadDocuments),
		takeEvery(actions.FILTER_DOCUMENTS, filterDocuments),
		takeEvery(actions.SAVE_DOCUMENT, saveDocument)
	]);
}
