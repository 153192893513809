const DOCUMENT = 'DEALSFEED_';

const actions = {
    LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
    LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
    LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

    SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
    SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

    RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
    RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

    TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
    FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

    LOAD_BY_TYPE_FIRESTORE: DOCUMENT + 'LOAD_BY_TYPE_FIRESTORE',
    LOAD_BY_TYPE_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_BY_TYPE_FIRESTORE_SUCCESS',
    LOAD_BY_TYPE_FIRESTORE_ERROR: DOCUMENT + 'LOAD_BY_TYPE_FIRESTORE_ERROR',

    loadFromFireStore: () => {
        return { type: actions.LOAD_FROM_FIRESTORE };
    },

    loadFromFireStoreSuccess: data => ({

        type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
        payload: { data },

    }),

    loadFromFireStoreError: error => ({
        type: actions.LOAD_FROM_FIRESTORE_ERROR,
        payload: { error },
    }),

    saveIntoFireStore: (data, actionName = 'insert') => ({
        type: actions.SAVE_INTO_FIRESTORE,
        payload: { data, actionName },
    }),

    toggleModal: (data = null) => ({
        type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
        payload: { data },
    }),

    update: data => ({
        type: actions.FIRESTORE_UPDATE,
        payload: { data },
    }),

    saveIntoFireStoreError: error => ({
        type: actions.SAVE_INTO_FIRESTORE_ERROR,
        payload: { error },
    }),

    getDealsByType: value => {
        return { type: actions.LOAD_BY_TYPE_FIRESTORE, value: value }
    },

    getDealsByTypeSuccess: data => ({
        type: actions.LOAD_BY_TYPE_FIRESTORE_SUCCESS,
        payload: { data }
    }),
};

export default actions;