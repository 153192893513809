import actions from './actions';

const initState = {
	isLoading: false,
	isSaving: false,
	showEditVisible: false,
	errorMessage: false,
	banners: [],
	bannerIdToRemove: null
};

export default function reducer(
	state = initState,
	{ type, payload, newRecord }
) {
	switch (type) {
		case actions.LOAD_BANNERS:
			return {
				...state,
				banners: [],
				errorMessage: '',
				isLoading: true
			};
		case actions.LOAD_BANNERS_SUCCESS:
			return {
				...state,
				errorMessage: '',
				isLoading: false,
				banners: payload.data
			};
		case actions.LOAD_BANNERS_FAILED:
			return {
				...initState,
				errorMessage: payload.error
			};
		case actions.REMOVE_BANNER:
			return {
				...state,
				bannerIdToRemove: payload.bannerId
			};
		case actions.SAVE_DOCUMENT:
			return {
				...state,
				isSaving: true
			};
		case actions.SAVE_DOCUMENT_SUCCESS:
			return {
				...state,
				isSaving: false,
				showEditVisible: false
			};
		case actions.SAVE_DOCUMENT_FAILED:
			return {
				...state,
				isSaving: false,
				showEditVisible: false,
				errerrorMessager: payload.error
			};
		case actions.EDIT_DOCUMENT:
			return {
				...state,
				showEditVisible: true
			};
		case actions.EDIT_DOCUMENT_CANCEL:
			return {
				...state,
				showEditVisible: false
			};
		default:
			return state;
	}
}
