import clone from 'clone';
import actions from './actions';

const initState = {
	users: [],
	initialUsers: false,
	currentUser: {},
	editableUser: {},
	isNewUser: false,
	enableEditView: false,
	isLoading: false,
	errorMessage: false,
	modalActive: false,
	userDB: {}
};

export default function cardReducer(
	state = initState,
	{ type, payload, ...action }
) {
	switch (type) {
		case actions.GET_USER: {
			return {
				...state
			};
		}
		case actions.GET_USER_SUCCESS: {
			return {
				...state,
				userDB: {
					email: payload.email,
					Username: payload.Username,
					surname: payload.surname,
					sapCode: payload.sapCode,
					company: payload.company,
					phoneNumber: payload.phoneNumber,
					phoneMobile: payload.phoneMobile,
					address1: payload.address1,
					address2: payload.address2,
					branchNumber: payload.branchNumber,
					neighborhood: payload.neighborhood,
					zipCode: payload.zipCode,
					city: payload.city,
					state1: payload.state1,
					repoAuto: payload.repoAuto,
					arquitectonic: payload.arquitectonic,
					industry: payload.industry,
					float: payload.float,
					executiveName: payload.executiveName,
					photoURL: payload.photoURL
				}
			};
		}
		case actions.UPDATE_USER: {
			const currentUser = action.user ? action.user : state.currentUser;
			return {
				...state,
				users: action.users,
				currentUser: clone(currentUser),
				initialUsers: true,
				isNewUser: false,
				enableEditView: false
			};
		}
		case actions.CURRENT_USER: {
			return {
				...state,
				isNewUser: false,
				enableEditView: false
			};
		}
		case actions.CURRENT_USER_SUCCESS: {
			return {
				...state,
				currentUser: {
					displayName: payload.displayName,
					email: payload.email,
					uid: payload.uid,
					phoneNumber: payload.phoneNumber,
					photoURL: payload.photoURL
				},
				isNewUser: false,
				enableEditView: false
			};
		}
		case actions.CURRENT_USER_ERROR: {
			return {
				...state,
				isLoading: false,
				errorMessage: 'There is a loading problem'
			};
		}
		case actions.TOGGLE_VIEW:
			return {
				...state,
				enableEditView: action.view,
				editableUser: clone(state.currentUser)
			};
		case actions.UPDATE_EDIT_USER:
			return {
				...state,
				editableUser: clone(action.user)
			};
		case actions.LOAD_FROM_FIRESTORE:
			return {
				...state,
				isLoading: true,
				errorMessage: false,
				users: [],
				modalActive: false
			};
		case actions.LOAD_ALL_USERS_FROM_FIRESTORE:
			return {
				...state,
				isLoading: true,
				errorMessage: false,
				users: [],
				modalActive: false
			};
		case actions.LOAD_FROM_FIRESTORE_SUCCESS:
			return {
				...state,
				isLoading: false,
				users: payload.data,
				errorMessage: false
			};
		case actions.LOAD_FROM_FIRESTORE_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: 'There is a loading problem'
			};
		case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
			return {
				...state,
				modalActive: !state.modalActive,
				investor:
					payload.data == null ? initState.investor : payload.data
			};
		case actions.FIRESTORE_UPDATE:
			return {
				...state,
				user: payload.data
			};
		case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: 'There is a loading problem'
			};
		case actions.UPDATE_USER_DB:
			return {
				...state,
				userDB: payload
			};
		case actions.UPDATE_USER_DB_SUCCESS:
			return {
				...state,
				userDB: {
					email: payload.email,
					Username: payload.Username,
					surname: payload.surname,
					sapCode: payload.sapCode,
					company: payload.company,
					phoneNumber: payload.phoneNumber,
					phoneMobile: payload.phoneMobile,
					address1: payload.address1,
					address2: payload.address2,
					branchNumber: payload.branchNumber,
					neighborhood: payload.neighborhood,
					zipCode: payload.zipCode,
					city: payload.city,
					state1: payload.state1,
					repoAuto: payload.repoAuto,
					arquitectonic: payload.arquitectonic,
					industry: payload.industry,
					float: payload.float,
					executiveName: payload.executiveName,
					photoURL: payload.photoURL
				}
			};
		case actions.UPDATE_USER_DB_ERROR:
			return {
				...state,
				errorMessage: 'There is a saved problem'
			};
		default:
			return state;
	}
}
