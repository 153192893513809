import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Popover from '@iso/components/uielements/popover';
import IntlMessages from '@iso/components/utility/intlMessages';
import userpic from '@iso/image/defaultProfile.png';
import authAction from '@iso/redux/auth/actions';
import actions from '@iso/redux/users/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';

const { logout } = authAction;

class TopbarUser extends Component {
	componentDidMount() {
		const { uid } = this.props;
		this.props.getUser(uid);
	}

	constructor(props) {
		super(props);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
		this.state = {
			visible: false
		};
	}
	hide() {
		this.setState({ visible: false });
	}
	handleVisibleChange() {
		this.setState({ visible: !this.state.visible });
	}

	userProfile() {
		const { photoURL } = this.props.userDB;

		if (!photoURL || photoURL === '' || photoURL === undefined) {
			return (
				<img
					style={{
						borderRadius: '150px'
					}}
					alt="user"
					src={userpic}
				/>
			);
		} else {
			return (
				<img
					style={{
						borderRadius: '150px'
					}}
					alt="user"
					src={photoURL}
				/>
			);
		}
	}

	render() {
		const content = (
			<TopbarDropdownWrapper className="isoUserDropdown">
				<Link
					to="/dashboard/profileUpdate"
					className="isoDropdownLink"
					onClick={() => this.setState({ visible: false })}
				>
					<IntlMessages id="topbar.profile" />
				</Link>
				<Link
					to="/dashboard/mobile"
					className="isoDropdownLink"
					onClick={() => this.setState({ visible: false })}
				>
					<IntlMessages id="topbar.mobile" />
				</Link>
				<a
					className="isoDropdownLink"
					onClick={this.props.logout}
					href="#"
				>
					<IntlMessages id="topbar.logout" />
				</a>
			</TopbarDropdownWrapper>
		);

		return (
			<Popover
				content={content}
				trigger="click"
				visible={this.state.visible}
				onVisibleChange={this.handleVisibleChange}
				arrowPointAtCenter={true}
				placement="bottomLeft"
			>
				<div className="isoImgWrapper">
					{this.userProfile()}
					<span className="userActivity online" />
				</div>
			</Popover>
		);
	}
}

const mapStateToProps = state => {
	// console.log(state);
	return {
		...state.Auth,
		...state.Users
	};
};

export default connect(
	mapStateToProps,
	{ logout, ...actions }
)(TopbarUser);
